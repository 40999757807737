import React, { useState, useRef, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import gsap from "gsap";
import Navigation from "./Navigation";
import Logo from "./Logo";
import Burger from "./Burger";
import { useMediaQuery } from "../../hooks";
import "./_header.scss";

const Header = ({
  items,
  logo,
  logoAlt,
  navPosition,
  imageData,
  heading,
  isCentered,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 749px)");

  const navRef = useRef();

  const [navItems] = useState(
    items.map(item => {
      return {
        ...item,
        ref: createRef(),
      };
    })
  );

  const [menuTL] = useState(
    gsap.timeline({
      paused: true,
      defaults: { duration: 1, ease: "expo.out" },
    })
  );

  useEffect(() => {
    if (navPosition === "right" || navPosition === "center") {
      const itemsRefs = navItems.map(item => item.ref.current);

      if (isSmallScreen) {
        menuTL
          .fromTo(navRef.current, { opacity: 0 }, { opacity: 1 })
          .fromTo(
            itemsRefs,
            { autoAlpha: 0, y: 48 },
            { autoAlpha: 1, y: 0, stagger: 0.1 },
            "-=0.4"
          )
          .reverse();
      } else {
        menuTL.seek(0).clear().pause();
        gsap.set([navRef.current, itemsRefs], { clearProps: "all" });
      }
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (navPosition === "overlay") {
      const itemsRefs = navItems.map(item => item.ref.current);

      menuTL
        .fromTo(navRef.current, { opacity: 0 }, { opacity: 1 })
        .fromTo(
          itemsRefs,
          { autoAlpha: 0, y: 48 },
          { autoAlpha: 1, y: 0, stagger: 0.1 },
          "-=0.4"
        )
        .reverse();
    }
  }, []);

  useEffect(() => {
    menuTL.reversed(!isMenuOpen);
  }, [isMenuOpen]);

  useEffect(() => {
    isMenuOpen && document.body.classList.add("menu--open");
    !isMenuOpen && document.body.classList.remove("menu--open");
  }, [isMenuOpen]);

  const toggleNav = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header__content" data-nav-position={navPosition}>
        <Logo logo={logo} logoAlt={logoAlt} />
        <Navigation
          items={navItems}
          isMenuOpen={isMenuOpen}
          ref={navRef}
          toggleNav={toggleNav}
        />
        <Burger toggleNav={toggleNav} isMenuOpen={isMenuOpen} />
      </div>
    </header>
  );
};

export default Header;

//Adding some propTypes for some checks and balances
Header.propTypes = {
  items: PropTypes.array.isRequired,
  logo: PropTypes.object.isRequired,
  logoAlt: PropTypes.string.isRequired,
  navPosition: PropTypes.oneOf(["center", "right", "overlay"]),
};

// Specifies the default value for the nvaPosition prop:
Header.defaultProps = {
  navPosition: "right",
};
