const navigation = [
  {
    id: "5258d430-d729-44b1-96ad-484c005d",
    name: "Sodyba",
    slug: "sodyba",
  },
  {
    id: "94596dd2-3e95-4587-bdfd-4f48af31b06d",
    name: "Kambariai",
    slug: "kambariai",
  },
  {
    id: "2fa83d93-e460-4298-a07d-70d0b3642397",
    name: "Maitinimas",
    slug: "maitinimas",
  },
  {
    id: "-oldrkeovjja0jz7h4g7a-",
    name: "Pramogos",
    slug: "pramogos",
  },
  {
    id: "-sjidqo4j0419bm128fbw-",
    name: "Vestuvės",
    slug: "vestuves",
  },
  {
    id: "2sjidqo4j4419bm128fbw-",
    name: "Galerija",
    slug: "galerija",
  },
  {
    id: "-7kyb70lssrukfk5eeprt-",
    name: "Kontaktai",
    slug: "kontaktai",
  },
];

export default navigation;
