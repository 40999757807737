import image from "src/assets/images/galerija/kudrenai01.jpg";
import image2 from "src/assets/images/galerija/kudrenai(66).jpg";
import image3 from "src/assets/images/galerija/kudrenai(4).jpg";
import image5 from "src/assets/images/galerija/kudrenai05.jpg";
import image6 from "src/assets/images/galerija/kudrenai06.jpg";
import image7 from "src/assets/images/galerija/kudrenai07.jpg";
import image8 from "src/assets/images/galerija/kudrenai08.jpg";
import image10 from "src/assets/images/galerija/kudrenai10.jpg";
import image11 from "src/assets/images/galerija/kudrenai11.jpg";
import image12 from "src/assets/images/galerija/kudrenai12.jpg";
import image13 from "src/assets/images/galerija/kudrenai13.jpg";
import image14 from "src/assets/images/galerija/kudrenai14.jpg";
import image15 from "src/assets/images/galerija/kudrenai15.jpg";
import image16 from "src/assets/images/galerija/kudrenai16.jpg";
import image17 from "src/assets/images/galerija/kudrenai17.jpg";
import image18 from "src/assets/images/galerija/kudrenai18.jpg";
import image19 from "src/assets/images/galerija/kudrenai19.jpg";
import image20 from "src/assets/images/galerija/kudrenai(23).jpg";
import image21 from "src/assets/images/galerija/kudrenai(24).jpg";
import image22 from "src/assets/images/galerija/kudrenai(25).jpg";
import image23 from "src/assets/images/galerija/kudrenai(7).jpg";
import image24 from "src/assets/images/galerija/kudrenai(5).jpg";
import image25 from "src/assets/images/galerija/kudrenai(6).jpg";
import image26 from "src/assets/images/galerija/kudrenai(8).jpg";
import image27 from "src/assets/images/galerija/kudrenai(9).jpg";
import image28 from "src/assets/images/galerija/kudrenai(10).jpg";
import image29 from "src/assets/images/galerija/kudrenai(11).jpg";
import image30 from "src/assets/images/galerija/kudrenai(12).jpg";
import image31 from "src/assets/images/galerija/kudrenai(13).jpg";
import image32 from "src/assets/images/galerija/kudrenai(14).jpg";
import image33 from "src/assets/images/galerija/kudrenai(15).jpg";
import image34 from "src/assets/images/galerija/kudrenai(16).jpg";
import image35 from "src/assets/images/galerija/kudrenai(17).jpg";
import image37 from "src/assets/images/galerija/kudrenai(19).jpg";
import image38 from "src/assets/images/galerija/kudrenai(20).jpg";
import image39 from "src/assets/images/galerija/kudrenai(21).jpg";
import image40 from "src/assets/images/galerija/kudrenai(22).jpg";
import image44 from "src/assets/images/galerija/kudrenai(26).jpg";
import image45 from "src/assets/images/galerija/kudrenai(27).jpg";
import image46 from "src/assets/images/galerija/kudrenai(28).jpg";
import image47 from "src/assets/images/galerija/kudrenai(29).jpg";
import image48 from "src/assets/images/galerija/kudrenai(30).jpg";
import image49 from "src/assets/images/galerija/kudrenai(31).jpg";
import image50 from "src/assets/images/galerija/kudrenai(32).jpg";
import image51 from "src/assets/images/galerija/kudrenai(33).jpg";
import image52 from "src/assets/images/galerija/kudrenai(34).jpg";
import image53 from "src/assets/images/galerija/kudrenai(35).jpg";
import image54 from "src/assets/images/galerija/kudrenai(36).jpg";
import image55 from "src/assets/images/galerija/kudrenai(37).jpg";
import image57 from "src/assets/images/galerija/kudrenai(39).jpg";
import image58 from "src/assets/images/galerija/kudrenai(40).jpg";
import image60 from "src/assets/images/galerija/kudrenai(42).jpg";
import image61 from "src/assets/images/galerija/kudrenai(43).jpg";
import image62 from "src/assets/images/galerija/kudrenai(44).jpg";
import image63 from "src/assets/images/galerija/kudrenai(45).jpg";
import image64 from "src/assets/images/galerija/kudrenai(46).jpg";
import image65 from "src/assets/images/galerija/kudrenai(47).jpg";
import image66 from "src/assets/images/galerija/kudrenai(48).jpg";
import image67 from "src/assets/images/galerija/kudrenai(49).jpg";
import image68 from "src/assets/images/galerija/kudrenai(50).jpg";
import image69 from "src/assets/images/galerija/kudrenai(51).jpg";
import image70 from "src/assets/images/galerija/kudrenai(52).jpg";
import image71 from "src/assets/images/galerija/kudrenai(53).jpg";
import image72 from "src/assets/images/galerija/kudrenai(54).jpg";
import image73 from "src/assets/images/galerija/kudrenai(55).jpg";
import image74 from "src/assets/images/galerija/kudrenai(56).jpg";
import image76 from "src/assets/images/galerija/kudrenai(58).jpg";
import image77 from "src/assets/images/galerija/kudrenai(59).jpg";
import image79 from "src/assets/images/galerija/kudrenai(61).jpg";
import image80 from "src/assets/images/galerija/kudrenai(62).jpg";
import image81 from "src/assets/images/galerija/kudrenai(63).jpg";
import image82 from "src/assets/images/galerija/kudrenai(64).jpg";
import image83 from "src/assets/images/galerija/kudrenai(65).jpg";

const photos = [
  {
    src: image,
    width: 1.5,
    height: 1,
  },
  {
    src: image2,
    width: 1,
    height: 1.2,
  },
  {
    src: image3,
    width: 1,
    height: 1,
  },
  {
    src: image5,
    width: 1,
    height: 1.4,
  },
  {
    src: image6,
    width: 1.4,
    height: 1,
  },
  {
    src: image7,
    width: 1,
    height: 1.4,
  },
  {
    src: image8,
    width: 1.5,
    height: 1,
  },
  {
    src: image10,
    width: 1.5,
    height: 1,
  },
  {
    src: image11,
    width: 1.5,
    height: 1,
  },
  {
    src: image12,
    width: 1.5,
    height: 1,
  },
  {
    src: image13,
    width: 1.5,
    height: 1,
  },
  {
    src: image14,
    width: 1.5,
    height: 1,
  },
  {
    src: image15,
    width: 1.5,
    height: 1,
  },
  {
    src: image16,
    width: 1.5,
    height: 1,
  },
  {
    src: image17,
    width: 1.5,
    height: 1,
  },
  {
    src: image18,
    width: 1.5,
    height: 1,
  },
  {
    src: image19,
    width: 1.5,
    height: 1,
  },
  {
    src: image20,
    width: 1,
    height: 1.3,
  },
  {
    src: image21,
    width: 1.5,
    height: 1,
  },
  {
    src: image22,
    width: 1,
    height: 1.4,
  },
  {
    src: image23,
    width: 1,
    height: 1,
  },
  {
    src: image24,
    width: 1.5,
    height: 1,
  },
  {
    src: image25,
    width: 1.3,
    height: 1,
  },
  {
    src: image26,
    width: 1.5,
    height: 1,
  },
  {
    src: image27,
    width: 1.5,
    height: 1,
  },
  {
    src: image28,
    width: 1.5,
    height: 1,
  },
  {
    src: image29,
    width: 1.5,
    height: 1,
  },
  {
    src: image30,
    width: 1.5,
    height: 1,
  },
  {
    src: image31,
    width: 1.5,
    height: 1,
  },
  {
    src: image32,
    width: 1.5,
    height: 1,
  },
  {
    src: image33,
    width: 1.5,
    height: 1,
  },
  {
    src: image34,
    width: 1.5,
    height: 1,
  },
  {
    src: image35,
    width: 1.5,
    height: 1,
  },
  {
    src: image37,
    width: 1,
    height: 1.2,
  },
  {
    src: image38,
    width: 1,
    height: 1.3,
  },
  {
    src: image39,
    width: 1.5,
    height: 1,
  },
  {
    src: image40,
    width: 1,
    height: 1.5,
  },
  {
    src: image44,
    width: 1.5,
    height: 1,
  },
  {
    src: image45,
    width: 1.5,
    height: 1,
  },
  {
    src: image46,
    width: 1.5,
    height: 1,
  },
  {
    src: image47,
    width: 1,
    height: 1.4,
  },
  {
    src: image48,
    width: 1.5,
    height: 1,
  },
  {
    src: image49,
    width: 1,
    height: 1.4,
  },
  {
    src: image50,
    width: 1,
    height: 1.2,
  },
  {
    src: image51,
    width: 1.5,
    height: 1,
  },
  {
    src: image52,
    width: 1,
    height: 1.3,
  },
  {
    src: image53,
    width: 1.5,
    height: 1,
  },
  {
    src: image54,
    width: 1.5,
    height: 1,
  },
  {
    src: image55,
    width: 1.5,
    height: 1,
  },
  {
    src: image57,
    width: 1.5,
    height: 1,
  },
  {
    src: image58,
    width: 1.5,
    height: 1,
  },
  {
    src: image60,
    width: 1,
    height: 1.3,
  },
  {
    src: image61,
    width: 1.5,
    height: 1,
  },
  {
    src: image62,
    width: 1,
    height: 1.3,
  },
  {
    src: image63,
    width: 1.5,
    height: 1,
  },
  {
    src: image64,
    width: 1.5,
    height: 1,
  },
  {
    src: image65,
    width: 1,
    height: 1.3,
  },
  {
    src: image66,
    width: 1,
    height: 1.4,
  },
  {
    src: image67,
    width: 1.3,
    height: 1,
  },
  {
    src: image68,
    width: 1,
    height: 1.5,
  },
  {
    src: image69,
    width: 1.5,
    height: 1,
  },
  {
    src: image70,
    width: 1,
    height: 1.4,
  },
  {
    src: image71,
    width: 1.5,
    height: 1,
  },
  {
    src: image72,
    width: 1.5,
    height: 1,
  },
  {
    src: image73,
    width: 1.5,
    height: 1,
  },
  {
    src: image74,
    width: 1.5,
    height: 1,
  },
  {
    src: image76,
    width: 1.5,
    height: 1,
  },
  {
    src: image77,
    width: 1.5,
    height: 1,
  },
  {
    src: image79,
    width: 1.5,
    height: 1,
  },
  {
    src: image80,
    width: 1.5,
    height: 1,
  },
  {
    src: image81,
    width: 1.5,
    height: 1,
  },
  {
    src: image82,
    width: 1.5,
    height: 1,
  },
  {
    src: image83,
    width: 1.5,
    height: 1,
  },
];

export default photos;
