const vestuves = {
  heading: "Jūsų gyvenimo šventė mūsų sodyboje",
  firstParagraph: `Sveikiname Jūsų porą ir dėkojame, kad svarstote savo gyvenimo šventę švęsti mūsų sodyboje. Vestuvės yra bene svarbiausia diena kiekvienos poros gyvenime. Romantiška, tobula, jaudinanti, įsimintina, stebuklinga, TA diena. Diena, kurios be proto lauki. Kurią norisi kartoti ir kartoti, kuria gyveni dar ilgai ir prisimeni nuolatos su šypsena veide bei šiluma širdyje. Sodyba „Kudrėnai” yra pasiruošuosi prisidėti prie svarbiausios Jūsų šventės kurdama ypatingą atmosferą ir gerus prisiminimus.`,
  secondParagraph: `Kiekvienos vestuvės mūsų sodyboje yra pritaikytos pagal jaunavedžių pageidavimus. Nesvarbu, ar ruošiatės didelei iškilmingai šventei su būriu draugų ir giminaičių, ar smulkiam artimiausių susibūrimui – mes paruošime aplinką taip, kad sukurtume ypatingą ir unikalią patirtį, kurios taip ieškote.`,
  thirdParagraph: `Modernus kambarių ir salės interjeras, susiliejantis su mus supančia nepaliesta, nuostabia gamta, skanus maistas, jaukumas ir ramybė, sklindantys iš mūsų darbuotojų. Visos šios išskirtinės detalės pavers Jūsų gyvenimo šventę pačia įsimintiniausia diena. Jeigu, vis dėlto, jaučiate nerimą ar turite klausimų – susisiekime. Mes visada esame pasiruošę padėti ir patarti įvairiais klausimais.`,
  buttonText: "Rezervuoti vizitą",
  buttonUrl: "/kontaktai",
};

export default vestuves;
