import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import PropTypes from "prop-types";

import "./_button.scss";

const Button = ({ url, children }) => {
  return (
    <div className="button__wrapper">
      <AniLink cover duration={1.5} bg="#d9e3e0" className="button" to={url}>
        {children}
      </AniLink>
    </div>
  );
};

Button.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;
