export const kontaktaiArray = [
  {
    id: 1,
    term: "Telefonas:",
    desc: "+370 682 33720",
    href: "tel:+37068233720",
  },
  {
    id: 2,
    term: "El. paštas:",
    desc: "kudrenai@gmail.com",
    href: "mailto:kudrenai@gmail.com",
  },
  {
    id: 3,
    term: "Adresas:",
    desc: "Upės g. 24, Kudrėnų k., Kauno r",
    href: "https://goo.gl/maps/rSWtU5a1yVJeJSYL9",
  },
  {
    id: 4,
    term: "GPS:",
    desc: "55.004205, 23.792969",
    href: "https://goo.gl/maps/rSWtU5a1yVJeJSYL9",
  },
];

export const kontaktai = {
  heading: "Rezervuokite vizitą",
  firstParagraph: `Nekantraujame pamatyti Jus mūsų kaimo turizmo sodyboje „Kudrėnai” ir laukiame, kol su mumis susisieksite.`,
};
