import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "./_footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__contacts">
          <AniLink
            cover
            bg="#d9e3e0"
            to="/kontaktai"
            className="footer__link footer__link--main"
          >
            Kontaktai
          </AniLink>
          <div className="footer__links-wrapper">
            <a
              href="https://goo.gl/maps/a5rp8p9mRQG2PrtV9"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link"
            >
              Upės g. 24, Kudrėnų k., Kauno r.
            </a>
            <a
              href="https://goo.gl/maps/a5rp8p9mRQG2PrtV9"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link"
            >
              GPS: 55.004205, 23.792969.
            </a>
            <a href="tel:+37068233720" className="footer__link">
              +370 682 33720
            </a>
            <a href="mailto:kudrenai@gmail.com" className="footer__link">
              kudrenai@gmail.com
            </a>
          </div>
        </div>
        <div className="footer__createdby">
          Autorius:{" "}
          <a
            href="https://tamulaitis.com"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link footer__link--author"
          >
            R. Tamulaitis
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
