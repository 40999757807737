const sodyba = {
  heading: "Kudrėnų sodybos istorija",
  firstParagraph: `Laikas... Greitai bėgantis laikas. Kartais taip norėtųsi
  sustabdyti šią akimirką ir jos niekada nepamiršti. Sodybos „Kudrėnai” tikslas sukurti prisiminimus.
  Prisiminimus visam gyvenimui.`,
  secondParagraph: `Nevėžio kraštovaizdžio draustinyje, ant upės kranto, tik 15 km nuo Kauno centro, įsikūrusi moderni, jauki kaimo turizmo sodyba „Kudrėnai“. Ši unikali vieta skirta tiek šeimyniniam poilsiui, tiek įvairioms darbo kolektyvų šventėms, konferencijoms, seminarams, mažiems ir dideliems pobūviams rengti.`,
  thirdParagraph: `Pagrindinio pastato pirmąjame aukšte įrengta salė pobūviui iki 80 asmenų. Iš salės pateksite į dengtą lauko terasą, kurioje galima rengti nedideles šventes arba pobūvio metu atsipūsti.`,
  fourthParagraph: `Sodybos teritorija yra didelė, prižiūrima, apšviesta ir saugoma visą parą. Kur beeitum, įrengti takeliai, galima pasivaikščioti, pasėdėti ant suoliuko prie obels ar ramiai pasisupti ant sūpynių laiptais nusileidus prie Nevėžio. Sodyboje daug erdvių, kur galima surengti sveikinimus, fotosesiją ar civilinės santuokos ceremoniją. Aplink nėra jokių kaimynų, supa tik gamta, ošiantys medžiai, čiulbantys paukščiai ir retkarčiais užklystantys gyvūnai. Kiekvienas, būdamas pas mus, jaučiasi taip, tarsi būtų kažkur toli toli nuo miesto, nuo triukšmo, kažkur, kur jaučiama ramybė ir jaukumas.`,
  buttonText: "Rezervuoti vizitą",
  buttonUrl: "/kontaktai",
};

export default sodyba;
